<template>
  <div class="experiences-info">
    <div class="pl-lg-9 px-sm-7 pt-sm-5 pt-3 px-3 d-flex flex-wrap align-center item-content">
      <div>
        <span class="font-weight-bold me-5">Status</span>
        <v-btn-toggle class="group-btn me-10" v-model="data.is_hidden" @change="updateHidden">
          <v-btn class="text-body-2" :value="false" outlined height="36">Visible</v-btn>
          <v-btn class="text-body-2" :value="true" outlined height="36">Hidden</v-btn>
        </v-btn-toggle>
      </div>
      <div>
        <span class="font-weight-bold me-5">Featured</span>
        <v-switch @change="updateFeatured" color="primary" class="white--text d-inline-block" v-model="data.featured" inset></v-switch>
      </div>
    </div>
    <v-divider class="mt-2"></v-divider>
    <div class="pl-lg-9 px-sm-7 pt-sm-7 pt-3 px-3 item-content">
      <div class="text-h6 font-weight-bold">General information</div>
      <div class="text--text mb-1">Provide general information about trip. Some information is necessary.</div>
      <div class="font-weight-bold mb-3">Category</div>
      <v-select
        class="field46"
        v-model="data.category"
        placeholder="Select category"
        :error-messages="categoryErrors"
        :items="itemsCategory"
        item-text="title"
        item-value="name"
        outlined
        dense
        required
        color="primary"
        height="46"
      ></v-select>
      <div class="font-weight-bold mb-3">Expert</div>
      <v-autocomplete
        class="field46 me-md-3"
        v-model="data.expert"
        placeholder="Select expert"
        :error-messages="expertErrors"
        :items="expertList"
        :item-text="textItem"
        item-value="id"
        return-object
        outlined
        dense
        required
        color="primary"
        height="46"
      >
        <template v-slot:selection="data">{{ data.item.first_name }}, {{ data.item.last_name }}</template>
        <template v-slot:item="data">{{ data.item.first_name }}, {{ data.item.last_name }}</template>
      </v-autocomplete>
      <div class="font-weight-bold mb-3">Destination country/city</div>
      <div class="d-flex flex-md-row flex-column" v-for="(item, i) in data.destinations" :key="i">
        <v-autocomplete
          class="field46 me-md-3"
          v-model="item.country"
          @change="getCity(item.country.id, i)"
          placeholder="Country"
          :items="countryList"
          item-text="name"
          item-value="id"
          return-object
          outlined
          dense
          required
          color="primary"
          height="46"
        ></v-autocomplete>
        <v-autocomplete
          class="field46 mt-sm-0 mt-n2 ms-md-3"
          v-model="item.city"
          placeholder="City"
          :error-messages="desCityErrors"
          :items="townList[serchArray(townList, item.city) || i]"
          @change="i + 1 == data.destinations.length ? data.destinations.push({ city: {}, country: {} }) : ''"
          item-text="name"
          item-value="id"
          return-object
          outlined
          dense
          required
          color="primary"
          height="46"
          :disabled="!item.country.id"
        ></v-autocomplete>
      </div>
      <div class="font-weight-bold mb-3">Availability dates</div>
      <div class="d-flex flex-md-row flex-column">
        <vc-date-picker
          locale="en"
          :input-debounce="500"
          v-model="data.available_since"
          :masks="masks"
          :model-config="modelConfig"
          :first-day-of-week="2"
          is-required
          color="blue"
          class="v-input width100 me-md-3"
        >
          <template v-slot="{ inputValue, inputEvents }">
            <v-text-field
              class="field46"
              :value="inputValue"
              v-on="inputEvents"
              placeholder="From"
              :error-messages="fromErrors"
              outlined
              dense
              color="primary"
              height="46"
            >
              <template v-slot:append>
                <v-img class="mt-2" src="@/assets/icon/calendar.svg"></v-img>
              </template>
            </v-text-field>
          </template>
        </vc-date-picker>
        <vc-date-picker
          locale="en"
          :input-debounce="500"
          v-model="data.available_till"
          :masks="masks"
          :model-config="modelConfig"
          :first-day-of-week="2"
          is-required
          color="blue"
          class="v-input width100 mt-sm-0 mt-n2 ms-md-3"
        >
          <template v-slot="{ inputValue, inputEvents }">
            <v-text-field
              class="field46"
              :value="inputValue"
              v-on="inputEvents"
              placeholder="To"
              :error-messages="toErrors"
              outlined
              dense
              color="primary"
              height="46"
            >
              <template v-slot:append>
                <v-img class="mt-2" src="@/assets/icon/calendar.svg"></v-img>
              </template>
            </v-text-field>
          </template>
        </vc-date-picker>
      </div>
      <div class="font-weight-bold mb-3">Duration, hours</div>
      <v-text-field
        class="field46"
        v-model="data.duration_hours"
        placeholder="0"
        :error-messages="durationErrors"
        type="number"
        outlined
        dense
        color="primary"
        height="46"
      ></v-text-field>
      <div class="d-flex align-md-center flex-md-row flex-column">
        <div class="v-input_small d-block width100 me-md-3">
          <div class="font-weight-bold mb-3">Price</div>
          <v-text-field
            class="field46 v-input_small"
            v-model="data.priceN"
            placeholder="From"
            :error-messages="priceErrors"
            suffix="SAR"
            outlined
            dense
            color="primary"
            height="46"
          ></v-text-field>
        </div>
        <div class="v-input_small d-block width100 mt-md-0 mt-n2 mx-md-3">
          <div class="font-weight-bold mb-3">VAT</div>
          <v-select
            class="field46"
            v-model="data.vat_option"
            placeholder="Select VAT"
            :error-messages="vatOptionEnErrors"
            :items="itemsVat"
            item-text="title"
            item-value="id"
            return-object
            outlined
            dense
            required
            color="primary"
            height="46"
          ></v-select>
        </div>
        <div class="v-input width100 mt-md-0 mt-n2 ms-md-3 mb-md-0 mb-3">
          <v-checkbox class="py-2 mt-3" color="success" v-model="data.vat_included" hide-details>
            <template v-slot:label>
              <div class="ms-2">
                <div class="black--text">VAT included in price</div>
              </div>
            </template>
          </v-checkbox>
        </div>
      </div>
      <div class="d-flex align-md-center flex-md-row flex-column">
        <div class="v-input_small d-block width100 me-md-3"></div>
        <div class="v-input_small d-block width100 mt-md-0 mt-n2 mx-md-3">
          <div class="font-weight-bold mb-3">Service fee</div>
          <v-select
            class="field46"
            v-model="data.service_fee_option"
            placeholder="Select service fee"
            :error-messages="serviceFeeOptionEnErrors"
            :items="itemsServiceFee"
            item-text="title"
            item-value="id"
            return-object
            outlined
            dense
            required
            color="primary"
            height="46"
          ></v-select>
        </div>
        <div class="v-input width100 mt-md-0 mt-n2 ms-md-3">
          <v-checkbox class="py-2 mt-3" color="success" v-model="data.service_fee_included" hide-details>
            <template v-slot:label>
              <div class="ms-2">
                <div class="black--text">Service fee included in price</div>
              </div>
            </template>
          </v-checkbox>
        </div>
      </div>
    </div>
    <v-divider class="mt-2"></v-divider>
    <div class="text-end py-3 px-sm-10 px-3">
      <v-btn @click="updateItem" depressed color="primary" large width="136">Save</v-btn>
    </div>
    <v-divider></v-divider>
    <div class="pl-lg-9 pa-sm-7 pa-3 item-content">
      <div class="text-h6 font-weight-bold">Loyalty programs</div>
      <div class="text--text">Select the programs that customer can apply during the booking process.</div>
      <div class="d-flex flex-wrap align-center px-2 py-4">
        <div>Mokafaa</div>
        <v-spacer></v-spacer>
        <v-btn-toggle class="group-btn" v-model="data.mokafaa_allowed" @change="updateLoyalty">
          <v-btn class="text-body-2" :value="true" outlined height="36">Available</v-btn>
          <v-btn class="text-body-2" :value="false" outlined height="36">Unavailable</v-btn>
        </v-btn-toggle>
      </div>
      <v-divider></v-divider>
      <div class="d-flex flex-wrap align-center px-2 py-4">
        <div>Digital Wallet</div>
        <v-spacer></v-spacer>
        <v-btn-toggle class="group-btn" v-model="data.dw_allowed" @change="updateLoyalty">
          <v-btn class="text-body-2" :value="true" outlined height="36">Available</v-btn>
          <v-btn class="text-body-2" :value="false" outlined height="36">Unavailable</v-btn>
        </v-btn-toggle>
      </div>
    </div>
    <v-divider></v-divider>
    <div class="pl-lg-9 pa-sm-7 pa-3 item-content">
      <v-card flat class="py-5 px-4 remove-card">
        <div class="error--text text-h6 font-weight-bold mb-2">Remove Experience</div>
        <div class="mb-1">Removing this experience will delete all information and attachments.</div>
        <div class="mb-7">Experience cannot be restored!</div>
        <v-btn @click="removeModal = true" depressed class="rounded" color="error" width="156" height="30">
          <span class="text-body-2">Remove Experiance</span>
        </v-btn>
      </v-card>
    </div>
    <v-dialog v-model="removeModal" max-width="480">
      <v-card flat class="pa-4">
        <div class="text-h6 font-weight-bold mb-3">Do you really want to delete this experience?</div>
        <div class="text--text text-body-2 mb-5">The information cannot be restored!</div>
        <div class="text-end">
          <v-btn @click="removeTrip" depressed class="rounded mr-3" color="error" width="106" height="30">
            <span class="text-body-2">Remove</span>
          </v-btn>
          <v-btn @click="removeModal = false" depressed outlined class="rounded" color="gray" width="106" height="30">
            <span class="text-body-2 black--text">Cancel</span>
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      removeModal: false,
      idx: null,
      masks: {
        input: 'MMM D, YYYY',
      },
      modelConfig: {
        type: 'number',
        mask: 'iso',
      },
      itemsCategory: [
        { name: 'economy', title: 'Economy' },
        { name: 'standard', title: 'Standard' },
        { name: 'vip', title: 'VIP' },
      ],
      townList: [],
      error: [],
    };
  },
  mounted() {
    this.citysList.length ? (this.townList = this.citysList) : '';
    this.$store.dispatch('getSettingsVat', '?all=true');
    this.$store.dispatch('getSettingsServiceFee', '?all=true');
  },
  methods: {
    notifi(btn) {
      if (btn == 'saved') {
        this.$notify({
          title: 'Done!',
          message: 'Experience saved successfully',
          type: 'success',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == 'update') {
        this.$notify({
          title: 'Done!',
          message: 'Experience updated successfully',
          type: 'success',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    textItem: (item) => `${item.first_name} ${item.last_name}`,
    serchArray(arr, item) {
      for (var i = 0, L = arr.length; i < L; i++) {
        const j = arr[i].findIndex((k) => k.id == item.id);
        if (j >= 0) {
          return i;
        }
      }
      return null;
    },
    async getCity(i, idx) {
      this.townList.splice(idx, 1);
      this.idx = idx;
      await this.$store.dispatch('getCityList', i, idx);
    },
    async updateHidden() {
      await this.$store.dispatch('hiddenExp', { is_hidden: this.data.is_hidden }).then(() => {
        this.notifi('update');
      });
    },
    async updateFeatured() {
      await this.$store.dispatch('featuredExp', { featured: this.data.featured }).then(() => {
        this.notifi('update');
      });
    },
    async removeTrip() {
      await this.$store.dispatch('deleteExp');
    },
    async updateLoyalty() {
      const data = new Object();
      data.mokafaa_allowed = this.data.mokafaa_allowed;
      data.dw_allowed = this.data.dw_allowed;
      await this.$store.dispatch('updateLoyaltyExp', data).then(() => {
        this.notifi('update');
      });
    },
    async updateItem() {
      this.error = [];
      const data = new Object();
      this.data.expert ? (data.expert = this.data.expert.id) : '';
      data.category = this.data.category;
      data.duration_hours = +this.data.duration_hours;
      if (this.data.destinations.length) {
        const array = [];
        for (let i = 0; i < this.data.destinations.length; i++) {
          array.push(this.data.destinations[i].city.id);
        }
        data.destinations = array;
      }
      data.available_since = this.data.available_since;
      data.available_till = this.data.available_till;
      data.title_en = this.data.title_en;
      data.title_ar = this.data.title_ar;
      data.short_description_en = this.data.short_description_en;
      data.short_description_ar = this.data.short_description_ar;
      data.description_en = this.data.description_en;
      data.description_ar = this.data.description_ar;
      data.price = this.data.priceN * 100;
      this.data.vat_option ? (data.vat_option = this.data.vat_option.id) : '';
      data.vat_included = this.data.vat_included;
      this.data.service_fee_option ? (data.service_fee_option = this.data.service_fee_option.id) : '';
      data.service_fee_included = this.data.service_fee_included;
      await this.$store
        .dispatch('updateExpItem', data)
        .then(() => {
          this.notifi('saved');
        })
        .catch((e) => {
          this.error = e.response.data.error;
        });
    },
  },
  watch: {
    cityList(val) {
      if (this.idx) {
        this.townList.splice(this.idx, 0, val);
        this.idx = null;
      } else if (val) {
        this.townList.push(val);
      }
      this.$nextTick(() => {
        this.$store.dispatch('setCitysList', this.townList);
      });
    },
  },
  computed: {
    data() {
      return this.$store.getters.exp;
    },
    expertList() {
      return this.$store.getters.expertsListShort;
    },
    countryList() {
      return this.$store.getters.countryList;
    },
    citysList() {
      return this.$store.getters.citysList;
    },
    cityList() {
      return this.$store.getters.cityList;
    },
    itemsVat() {
      return this.$store.getters.settingsVat;
    },
    itemsServiceFee() {
      return this.$store.getters.settingsServiceFee;
    },
    categoryErrors() {
      const errors = [];
      this.error.find((item) => item == 'category__required') && errors.push('Please select category');
      this.error.find((item) => item == 'category__invalid') && errors.push('Selected category is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    expertErrors() {
      const errors = [];
      this.error.find((item) => item == 'expert__required') && errors.push('Please select trip expert');
      this.error.find((item) => item == 'expert__invalid') && errors.push('Selected trip expert is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    desCityErrors() {
      const errors = [];
      this.error.find((item) => item == 'destination_city__required') && errors.push('Please enter destination city');
      this.error.find((item) => item == 'destination_city__invalid') && errors.push('Provided destination city is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    fromErrors() {
      const errors = [];
      this.error.find((item) => item == 'available_since__required') && errors.push('Please enter available since');
      this.error.find((item) => item == 'available_since__invalid') && errors.push('Provided available since is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    toErrors() {
      const errors = [];
      this.error.find((item) => item == 'available_till__required') && errors.push('Please enter available till');
      this.error.find((item) => item == 'available_till__invalid') && errors.push('Provided available till is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    durationErrors() {
      const errors = [];
      this.error.find((item) => item == 'duration_hours__required') && errors.push('Please enter duration hours');
      this.error.find((item) => item == 'duration_hours__invalid') && errors.push('Provided duration hours is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    priceErrors() {
      const errors = [];
      this.error.find((item) => item == 'price__required') && errors.push('Please enter price, SAR');
      this.error.find((item) => item == 'price__invalid') && errors.push('Provided price is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    vatOptionEnErrors() {
      const errors = [];
      this.error.find((item) => item == 'vat_option__required') && errors.push('Please select VAT type');
      this.error.find((item) => item == 'vat_option__invalid') && errors.push('Selected VAT type is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    serviceFeeOptionEnErrors() {
      const errors = [];
      this.error.find((item) => item == 'service_fee_option__required') && errors.push('Please select service fee type');
      this.error.find((item) => item == 'service_fee_option__invalid') && errors.push('Selected service fee type is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
  },
};
</script>

<style lang="scss">
#app .experiences-info {
  .v-input {
    max-width: 371px;
  }
  .v-input_small {
    max-width: 186px;
  }
  .remove-card {
    background: var(--v-gray-lighten4);
    border: 1px solid var(--v-gray-lighten2);
  }
  @media screen and (max-width: 700px) {
    .v-input_small,
    .v-input {
      max-width: initial !important;
    }
    .group-btn {
      display: grid;
      button {
        border-width: thin !important;
        border-radius: 4px !important;
      }
    }
  }
}
</style>
