<template>
  <div class="experiences-act">
    <div class="pl-lg-9 pa-sm-7 pa-3 item-content">
      <div class="text-h6 font-weight-bold mb-2">Activities</div>
      <div class="d-flex flex-wrap align-center">
        <v-chip
          outlined
          v-for="i in activList"
          :key="i.id"
          :class="data.activities.some((j) => j.id == i.id) ? 'primary lighten-1 primary--text' : 'text--text'"
          class="text-body-1 me-4 my-2"
          @click="updateActiv(i.id)"
          style="height: 38px"
        >
          <v-icon v-if="data.activities.find((j) => j.id == i.id)" color="primary" class="ms-1 me-2">mdi-check mdi-24px</v-icon>
          {{ i.title_en }} <span class="px-2 gray--text text--lighten-3">|</span> {{ i.title_ar }}
        </v-chip>
      </div>
      <div class="mt-4">
        <v-btn @click="updateActiv('all')" depressed height="30" width="106" color="primary" class="text-body-2 me-3">Choose all</v-btn>
        <v-btn @click="updateActiv('empty')" height="30" width="106" outlined class="text-body-2">Reset all</v-btn>
      </div>
    </div>
    <v-divider></v-divider>
    <div class="pl-lg-9 pa-sm-7 pa-3 item-content">
      <div class="text-h6 font-weight-bold">Components</div>
      <div class="text--text">Provide the components that are exactly included to the trip and are exactly unincluded</div>
      <div>
        <div v-for="(i, idx) in data.components" :key="idx + 3" class="d-flex align-center my-4">
          <v-switch
            @change="updateComponent(i)"
            v-model="i.included"
            color="primary"
            class="white--text mt-0"
            hide-details
            inset
            :disabled="!i.id"
          ></v-switch>
          <v-select
            @change="!i.id ? addComponent(i) : updateComponent(i)"
            v-model="i.component"
            :items="componentList"
            item-text="title_en"
            item-value="id"
            placeholder="Add new"
            outlined
            dense
            hide-details
            return-object
            class="field46"
            height="46"
          >
            <template v-slot:selection="{ item }">
              <div class="d-flex align-center justify-space-between width100">
                <div>{{ item.title_en }}</div>
                <div class="gray--text text--lighten-3 mx-2">|</div>
                <div class="d-flex align-center">
                  <div>{{ item.title_ar }}</div>
                  <div class="gray--text text--lighten-2 ps-2">|</div>
                </div>
              </div>
            </template>
            <template v-slot:item="{ item }">
              <div class="d-flex align-center justify-space-between">
                <div>{{ item.title_en }}</div>
                <div class="gray--text text--lighten-3 mx-2">|</div>
                <div>{{ item.title_ar }}</div>
              </div>
            </template>
          </v-select>
          <v-btn v-if="!!i.id" @click="deleteComponent(i, idx)" icon class="ms-2">
            <v-icon color="gray lighten-1">mdi-close</v-icon>
          </v-btn>
          <v-card v-else tile flat min-width="36" height="36" class="ms-2"></v-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      error: [],
    };
  },
  methods: {
    notifi(btn) {
      if (btn == 'saved') {
        this.$notify({
          title: 'Done!',
          message: 'Experience saved successfully',
          type: 'success',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == 'update') {
        this.$notify({
          title: 'Done!',
          message: 'Experience updated successfully',
          type: 'success',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == 'remove') {
        this.$notify({
          title: 'Done!',
          message: 'Experience component remove',
          type: 'success',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    async updateActiv(d) {
      const data = new Object();
      data.activities = [];
      if (d == 'all') {
        for (let i = 0; i < this.activList.length; i++) {
          data.activities.push(this.activList[i].id);
        }
        await this.$store.dispatch('updateActivitiesExp', data);
      } else if (d == 'empty') {
        await this.$store.dispatch('updateActivitiesExp', data);
      } else {
        if (this.data.activities.find((j) => j.id == d)) {
          const item = this.data.activities.find((j) => j.id == d);
          const idx = this.data.activities.indexOf(item);
          this.data.activities.splice(idx, 1);
          for (let i = 0; i < this.data.activities.length; i++) {
            data.activities.push(this.data.activities[i].id);
          }
        } else {
          this.data.activities.push({ id: d });
          for (let i = 0; i < this.data.activities.length; i++) {
            data.activities.push(this.data.activities[i].id);
          }
        }
        await this.$store.dispatch('updateActivitiesExp', data);
      }
      this.notifi('update');
    },
    async addComponent(i) {
      const data = new Object();
      data.travel_component = i.component.id;
      data.included = false;
      await this.$store.dispatch('addComponentsExp', data).then(() => {
        this.notifi('saved');
      });
    },
    async updateComponent(i) {
      const data = new Object();
      data.travel_component = i.component.id;
      data.included = i.included;
      await this.$store.dispatch('updateComponentsExp', { id: i.id, data: data }).then(() => {
        this.notifi('update');
      });
    },
    async deleteComponent(i, idx) {
      if (this.data.components[idx].id) {
        await this.$store.dispatch('deleteComponentsExp', i.id).then(() => {
          this.data.components.splice(idx, 1);
        });
      } else {
        this.data.components.splice(idx, 1);
      }
      this.notifi('remove');
    },
  },
  computed: {
    data() {
      return this.$store.getters.exp;
    },
    activList() {
      return this.$store.getters.tripTvActiv;
    },
    componentList() {
      return this.$store.getters.tripTvComponent;
    },
  },
};
</script>

<style lang="scss">
.experiences-act {
  .v-btn {
    border-radius: 4px;
  }
}
</style>
