<template>
  <div class="experiences-item">
    <div class="font-weight-bold">
      <router-link class="disabled--text" to="/experiences">Experiences</router-link>
      <v-icon color="disabled" class="text-h6">mdi-chevron-right</v-icon>
      <span class="black--text">{{ data.title_en }}</span>
    </div>
    <v-card class="mt-4" width="100%" outlined>
      <div class="d-flex wrap">
        <v-card color="item-menu py-2" flat>
          <div
            v-for="item in menuItems"
            :key="item.title"
            :class="link == item.link ? 'gray lighten-4 pl-3 pr-5' : 'px-5'"
            @click="clickMenu(item.link)"
            class="item-menu_item link"
          >
            <div>{{ item.title }}</div>
          </div>
          <div
            :class="link == 'saudi-surprise' ? 'gray lighten-4 pl-3 pr-5' : 'px-5'"
            @click="clickMenu('saudi-surprise')"
            class="item-menu_item link"
            v-if="data.subtype == 'surprise'"
          >
            <div class="d-flex align-center">
              <img src="@/assets/icon/surprise.svg" alt="icon" />
              <div class="ms-2">Saudi surprise</div>
            </div>
          </div>
        </v-card>
        <v-divider style="z-index: 90" vertical></v-divider>
        <div class="width100" v-if="link == 'general'">
          <Info />
        </div>
        <div class="width100" v-if="link == 'description'">
          <Description />
        </div>
        <div class="width100" v-if="link == 'activities'">
          <Activities />
        </div>
        <div class="width100" v-if="link == 'additional-info'">
          <Additional />
        </div>
        <div class="width100" v-if="link == 'schedule'">
          <Schedule />
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import Info from '@/components/experiences/Info';
import Description from '@/components/experiences/Description';
import Activities from '@/components/experiences/Activities';
import Additional from '@/components/experiences/Additional';
import Schedule from '@/components/experiences/Schedule';

export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  components: {
    Info,
    Description,
    Activities,
    Additional,
    Schedule,
  },
  data() {
    return {
      i: 0,
      link: this.$route.params.tab,
      menuItems: [
        { link: 'general', title: 'General info' },
        { link: 'description', title: 'Description' },
        { link: 'activities', title: 'Activities and components' },
        { link: 'additional-info', title: 'Additional info' },
        { link: 'schedule', title: 'Schedule' },
      ],
    };
  },
  mounted() {
    this.$store.dispatch('getExpItem');
    this.$store.dispatch('getCountryList');
    this.$store.dispatch('getExpertShort');
    this.$store.dispatch('getTripTravelActiv');
    this.$store.dispatch('getTripTravelConponents');
  },
  methods: {
    clickMenu(l) {
      this.link = l;
      this.$router.push(`/experiences/${this.$route.params.expId}/${l}`);
    },
  },
  watch: {
    data(val) {
      if (val) {
        if (val.destinations.length) {
          for (let i = 0; i < val.destinations.length; i++) {
            this.$nextTick(() => {
              this.$store.dispatch('getCityList', val.destinations[i].country.id);
            });
          }
        }
      }
    },
  },
  computed: {
    data() {
      return this.$store.getters.exp;
    },
    countryList() {
      return this.$store.getters.countryList;
    },
  },
  destroyed() {
    this.$store.dispatch('setExpItem');
    this.$store.dispatch('setCountryList');
    this.$store.dispatch('setCityList');
    this.$store.dispatch('setExpertShort');
  },
};
</script>
