<template>
  <div class="experiences-add">
    <div class="pl-lg-9 pa-sm-7 pa-3 item-content">
      <div class="text-h6 font-weight-bold mb-2">Who can join</div>
      <div v-for="(i, j) in data.who_can_join" :key="i.id" class="d-flex align-center">
        <v-card outlined min-height="46" class="my-2 px-3 py-2 d-flex align-center justify-space-between width100">
          <div class="width50">{{ i.text_en }}</div>
          <v-divider style="border-width: thin" vertical class="gray lighten-3 mx-3 my-2"></v-divider>
          <div class="width50 text-rtl">{{ i.text_ar }}</div>
        </v-card>
        <v-btn @click="deleteWho(i, j)" icon class="ms-2"><v-icon color="gray lighten-1">mdi-close</v-icon></v-btn>
      </div>
      <div class="mt-4">
        <v-btn @click="(whoModal = true), (openWindow = 1)" depressed height="30" width="106" color="primary" class="text-body-2 me-3">
          Add new
        </v-btn>
      </div>
    </div>
    <v-divider></v-divider>
    <div class="pl-lg-9 pa-sm-7 pa-3 item-content">
      <div class="text-h6 font-weight-bold">Know before you go</div>
      <div v-for="(i, j) in data.know_before_you_go" :key="i.id" class="d-flex align-center">
        <v-card outlined min-height="46" class="my-2 px-3 py-2 d-flex align-center justify-space-between width100">
          <div class="width50">{{ i.text_en }}</div>
          <v-divider style="border-width: thin" vertical class="gray lighten-3 mx-3 my-2"></v-divider>
          <div class="width50 text-rtl">{{ i.text_ar }}</div>
        </v-card>
        <v-btn @click="deleteKnow(i, j)" icon class="ms-2"><v-icon color="gray lighten-1">mdi-close</v-icon></v-btn>
      </div>
      <div class="mt-4">
        <v-btn @click="(knowModal = true), (openWindow = 1)" depressed height="30" width="106" color="primary" class="text-body-2 me-3">
          Add new
        </v-btn>
      </div>
    </div>
    <v-divider></v-divider>
    <div class="pl-lg-9 pa-sm-7 pa-3 item-content">
      <div class="text-h6 font-weight-bold">Not to do list</div>
      <div v-for="(i, j) in data.not_to_do" :key="i.id" class="d-flex align-center">
        <v-card outlined min-height="46" class="my-2 px-3 py-2 d-flex align-center justify-space-between width100">
          <div class="width50">{{ i.text_en }}</div>
          <v-divider style="border-width: thin" vertical class="gray lighten-3 mx-3 my-2"></v-divider>
          <div class="width50 text-rtl">{{ i.text_ar }}</div>
        </v-card>
        <v-btn @click="deleteTodo(i, j)" icon class="ms-2"><v-icon color="gray lighten-1">mdi-close</v-icon></v-btn>
      </div>
      <div class="mt-4">
        <v-btn @click="(todoModal = true), (openWindow = 1)" depressed height="30" width="106" color="primary" class="text-body-2 me-3">
          Add new
        </v-btn>
      </div>
    </div>
    <v-divider></v-divider>
    <div class="pl-lg-9 pa-sm-7 pa-3 item-content">
      <div class="text-h6 font-weight-bold">FAQ</div>
      <div v-for="(i, j) in data.faqs" :key="i.id" class="d-flex align-center">
        <v-card outlined min-height="46" class="my-2 px-3 py-2 d-flex align-center justify-space-between width100">
          <div class="width50">
            <div class="mb-1">{{ i.question_en }}</div>
            <div class="text--text">{{ i.answer_en }}</div>
          </div>
          <v-divider style="border-width: thin" vertical class="gray lighten-3 mx-3 my-2"></v-divider>
          <div class="width50 text-rtl">
            <div class="mb-1">{{ i.question_ar }}</div>
            <div class="text--text">{{ i.answer_ar }}</div>
          </div>
        </v-card>
        <v-btn @click="deleteFaq(i, j)" icon class="ms-2"><v-icon color="gray lighten-1">mdi-close</v-icon></v-btn>
      </div>
      <div class="mt-4">
        <v-btn @click="(faqModal = true), (openWindow = 1)" depressed height="30" width="106" color="primary" class="text-body-2 me-3">
          Add new
        </v-btn>
      </div>
    </div>
    <v-dialog v-model="whoModal" max-width="700">
      <v-card tile>
        <div class="pt-5 pb-3 px-10 text-center">
          <div class="font-weight-bold mb-3">Who can join</div>
          <v-btn-toggle class="group-btn" v-model="openWindow">
            <v-btn class="text-body-2" :value="1" @click="openWindow = 1" outlined height="38">Select</v-btn>
            <v-btn class="text-body-2" :value="2" @click="openWindow = 2" outlined height="38">Create new</v-btn>
          </v-btn-toggle>
        </div>
        <v-divider></v-divider>
        <v-card flat tile class="px-10 overflow-y-auto" v-if="openWindow == 1" height="322">
          <v-radio-group v-model="idValue" class="mt-3 pt-0">
            <v-radio
              v-for="n in whoList"
              :key="n.id"
              class="px-4 radio"
              :class="n.id == idValue ? 'border-blue' : 'border-gray'"
              color="primary"
              on-icon="mdi-record-circle"
              :value="n.id"
            >
              <template v-slot:label>
                <div class="width50 black--text">{{ n.text_en }}</div>
                <v-divider style="border-width: thin" vertical class="gray lighten-3 mx-3"></v-divider>
                <div class="width50 black--text text-right">{{ n.text_ar }}</div>
              </template>
            </v-radio>
          </v-radio-group>
        </v-card>
        <v-card flat tile class="px-10 pt-4 overflow-y-auto" v-if="openWindow == 2" height="322">
          <div class="mb-5">Provide information in English and Arabic</div>
          <div class="d-flex flex-md-row flex-column multi-direct">
            <v-textarea
              class="width100 me-md-3"
              v-model="modalData.text_en"
              placeholder="Description"
              :error-messages="textEnErrors"
              auto-grow
              rows="4"
              row-height="30"
              outlined
              dense
              required
              color="primary"
            ></v-textarea>
            <v-textarea
              class="width100 ms-md-3"
              v-model="modalData.text_ar"
              placeholder="وصف كامل"
              :error-messages="textArErrors"
              auto-grow
              rows="4"
              row-height="30"
              outlined
              dense
              required
              color="primary"
            ></v-textarea>
          </div>
        </v-card>
        <v-divider></v-divider>
        <div class="text-end px-10 py-5">
          <v-btn @click="whoModal = false" width="106" height="30" outlined class="text-body-2 rounded me-3">Cancel</v-btn>
          <v-btn
            @click="openWindow == 1 ? addWhoOption() : createWhoOption()"
            depressed
            width="106"
            height="30"
            color="primary"
            class="text-body-2 rounded"
            :disabled="openWindow == 1 && !idValue"
          >
            Done
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="knowModal" max-width="700">
      <v-card tile>
        <div class="pt-5 pb-3 px-10 text-center">
          <div class="font-weight-bold mb-3">Know before you go</div>
          <v-btn-toggle class="group-btn" v-model="openWindow">
            <v-btn class="text-body-2" :value="1" @click="openWindow = 1" outlined height="38">Select</v-btn>
            <v-btn class="text-body-2" :value="2" @click="openWindow = 2" outlined height="38">Create new</v-btn>
          </v-btn-toggle>
        </div>
        <v-divider></v-divider>
        <v-card flat tile class="px-10 overflow-y-auto" v-if="openWindow == 1" height="322">
          <v-radio-group v-model="idValue" class="mt-3 pt-0">
            <v-radio
              v-for="n in knowList"
              :key="n.id"
              class="px-4 radio"
              :class="n.id == idValue ? 'border-blue' : 'border-gray'"
              color="primary"
              on-icon="mdi-record-circle"
              :value="n.id"
            >
              <template v-slot:label>
                <div class="width50 black--text">{{ n.text_en }}</div>
                <v-divider style="border-width: thin" vertical class="gray lighten-3 mx-3"></v-divider>
                <div class="width50 black--text text-right">{{ n.text_ar }}</div>
              </template>
            </v-radio>
          </v-radio-group>
        </v-card>
        <v-card flat tile class="px-10 pt-4 overflow-y-auto" v-if="openWindow == 2" height="322">
          <div class="mb-5">Provide information in English and Arabic</div>
          <div class="d-flex flex-md-row flex-column multi-direct">
            <v-textarea
              class="width100 me-md-3"
              v-model="modalData.text_en"
              placeholder="Description"
              :error-messages="textEnErrors"
              auto-grow
              rows="4"
              row-height="30"
              outlined
              dense
              required
              color="primary"
            ></v-textarea>
            <v-textarea
              class="width100 ms-md-3"
              v-model="modalData.text_ar"
              placeholder="وصف كامل"
              :error-messages="textArErrors"
              auto-grow
              rows="4"
              row-height="30"
              outlined
              dense
              required
              color="primary"
            ></v-textarea>
          </div>
        </v-card>
        <v-divider></v-divider>
        <div class="text-end px-10 py-5">
          <v-btn @click="knowModal = false" width="106" height="30" outlined class="text-body-2 rounded me-3">Cancel</v-btn>
          <v-btn
            @click="openWindow == 1 ? addKnowOption() : createKnowOption()"
            depressed
            width="106"
            height="30"
            color="primary"
            class="text-body-2 rounded"
            :disabled="openWindow == 1 && !idValue"
          >
            Done
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="todoModal" max-width="700">
      <v-card tile>
        <div class="pt-5 pb-3 px-10 text-center">
          <div class="font-weight-bold mb-3">Not to do list</div>
          <v-btn-toggle class="group-btn" v-model="openWindow">
            <v-btn class="text-body-2" :value="1" @click="openWindow = 1" outlined height="38">Select</v-btn>
            <v-btn class="text-body-2" :value="2" @click="openWindow = 2" outlined height="38">Create new</v-btn>
          </v-btn-toggle>
        </div>
        <v-divider></v-divider>
        <v-card flat tile class="px-10 overflow-y-auto" v-if="openWindow == 1" height="322">
          <v-radio-group v-model="idValue" class="mt-3 pt-0">
            <v-radio
              v-for="n in todoList"
              :key="n.id"
              class="px-4 radio"
              :class="n.id == idValue ? 'border-blue' : 'border-gray'"
              color="primary"
              on-icon="mdi-record-circle"
              :value="n.id"
            >
              <template v-slot:label>
                <div class="width50 black--text">{{ n.text_en }}</div>
                <v-divider style="border-width: thin" vertical class="gray lighten-3 mx-3"></v-divider>
                <div class="width50 black--text text-right">{{ n.text_ar }}</div>
              </template>
            </v-radio>
          </v-radio-group>
        </v-card>
        <v-card flat tile class="px-10 pt-4 overflow-y-auto" v-if="openWindow == 2" height="322">
          <div class="mb-5">Provide information in English and Arabic</div>
          <div class="d-flex flex-md-row flex-column multi-direct">
            <v-textarea
              class="width100 me-md-3"
              v-model="modalData.text_en"
              placeholder="Description"
              :error-messages="textEnErrors"
              auto-grow
              rows="4"
              row-height="30"
              outlined
              dense
              required
              color="primary"
            ></v-textarea>
            <v-textarea
              class="width100 ms-md-3"
              v-model="modalData.text_ar"
              placeholder="وصف كامل"
              :error-messages="textArErrors"
              auto-grow
              rows="4"
              row-height="30"
              outlined
              dense
              required
              color="primary"
            ></v-textarea>
          </div>
        </v-card>
        <v-divider></v-divider>
        <div class="text-end px-10 py-5">
          <v-btn @click="todoModal = false" width="106" height="30" outlined class="text-body-2 rounded me-3">Cancel</v-btn>
          <v-btn
            @click="openWindow == 1 ? addTodoOption() : createTodoOption()"
            depressed
            width="106"
            height="30"
            color="primary"
            class="text-body-2 rounded"
            :disabled="openWindow == 1 && !idValue"
          >
            Done
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="faqModal" max-width="700">
      <v-card tile>
        <div class="pt-5 pb-3 px-10 text-center">
          <div class="font-weight-bold mb-3">FAQ</div>
          <v-btn-toggle class="group-btn" v-model="openWindow">
            <v-btn class="text-body-2" :value="1" @click="openWindow = 1" outlined height="38">Select</v-btn>
            <v-btn class="text-body-2" :value="2" @click="openWindow = 2" outlined height="38">Create new</v-btn>
          </v-btn-toggle>
        </div>
        <v-divider></v-divider>
        <v-card flat tile class="px-10 overflow-y-auto" v-if="openWindow == 1" height="322">
          <v-radio-group v-model="idValue" class="mt-3 pt-0">
            <v-radio
              v-for="n in faqList"
              :key="n.id"
              class="px-4 radio"
              :class="n.id == idValue ? 'border-blue' : 'border-gray'"
              color="primary"
              on-icon="mdi-record-circle"
              style="height: auto; min-height: 50px"
              :value="n.id"
            >
              <template v-slot:label>
                <div class="width50 my-3">
                  <div class="black--text mb-1">{{ n.question_en }}</div>
                  <div>{{ n.answer_en }}</div>
                </div>
                <v-divider style="border-width: thin" vertical class="gray lighten-3 mx-3"></v-divider>
                <div class="width50 my-3 text-right">
                  <div class="black--text mb-1">{{ n.question_ar }}</div>
                  <div>{{ n.answer_ar }}</div>
                </div>
              </template>
            </v-radio>
          </v-radio-group>
        </v-card>
        <v-card flat tile class="px-10 pt-4 overflow-y-auto" v-if="openWindow == 2" height="322">
          <div class="mb-5">Provide information in English and Arabic</div>
          <div class="d-flex flex-md-row flex-column multi-direct">
            <v-text-field
              class="field46 width100 me-md-3"
              v-model="modalData.question_en"
              placeholder="Provide title in English"
              :error-messages="questionEnErrors"
              outlined
              dense
              required
              color="primary"
              height="46"
            ></v-text-field>
            <v-text-field
              class="field46 width100 ms-md-3"
              v-model="modalData.question_ar"
              placeholder="اكتب العنوان باللغة العربية"
              :error-messages="questionArErrors"
              outlined
              dense
              required
              color="primary"
              height="46"
            ></v-text-field>
          </div>
          <div class="d-flex flex-md-row flex-column multi-direct">
            <v-textarea
              class="width100 me-md-3"
              v-model="modalData.answer_en"
              placeholder="Provide answer in English"
              :error-messages="answerEnErrors"
              auto-grow
              rows="4"
              row-height="30"
              outlined
              dense
              required
              color="primary"
            ></v-textarea>
            <v-textarea
              class="width100 ms-md-3"
              v-model="modalData.answer_ar"
              placeholder="قدم الإجابة باللغة العربية"
              :error-messages="answerArErrors"
              auto-grow
              rows="4"
              row-height="30"
              outlined
              dense
              required
              color="primary"
            ></v-textarea>
          </div>
        </v-card>
        <v-divider></v-divider>
        <div class="text-end px-10 py-5">
          <v-btn @click="faqModal = false" width="106" height="30" outlined class="text-body-2 rounded me-3">Cancel</v-btn>
          <v-btn
            @click="openWindow == 1 ? addFaqOption() : createFaqOption()"
            depressed
            width="106"
            height="30"
            color="primary"
            class="text-body-2 rounded"
            :disabled="openWindow == 1 && !idValue"
          >
            Done
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      whoModal: false,
      knowModal: false,
      todoModal: false,
      faqModal: false,
      modalData: {},
      openWindow: 1,
      idValue: '',
      error: [],
    };
  },
  mounted() {
    this.$store.dispatch('getTripTravelWho');
    this.$store.dispatch('getTripTravelKnow');
    this.$store.dispatch('getTripTravelTodo');
    this.$store.dispatch('getTripTravelFaq');
  },
  methods: {
    async createWhoOption() {
      this.error = [];
      await this.$store
        .dispatch('createTripTravelWho', this.modalData)
        .then((res) => {
          const data = new Object();
          data.who_can_join = res.data.id;
          this.$store.dispatch('addWhoExp', data).then(() => {
            this.whoModal = false;
          });
          this.whoList.push(res.data);
        })
        .catch((e) => {
          this.error = e.response.data.error;
        });
    },
    async addWhoOption() {
      this.error = [];
      const data = new Object();
      data.who_can_join = this.idValue;
      await this.$store.dispatch('addWhoExp', data).then(() => {
        this.whoModal = false;
      });
    },
    async deleteWho(i, j) {
      await this.$store.dispatch('deleteWhoExp', i.id).then(() => {
        this.data.who_can_join.splice(j, 1);
      });
    },
    async createKnowOption() {
      this.error = [];
      await this.$store
        .dispatch('createTripTravelKnow', this.modalData)
        .then((res) => {
          const data = new Object();
          data.know_before_you_go = res.data.id;
          this.$store.dispatch('addKnowExp', data).then(() => {
            this.knowModal = false;
          });
          this.knowList.push(res.data);
        })
        .catch((e) => {
          this.error = e.response.data.error;
        });
    },
    async addKnowOption() {
      this.error = [];
      const data = new Object();
      data.know_before_you_go = this.idValue;
      await this.$store.dispatch('addKnowExp', data).then(() => {
        this.knowModal = false;
      });
    },
    async deleteKnow(i, j) {
      await this.$store.dispatch('deleteKnowExp', i.id).then(() => {
        this.data.know_before_you_go.splice(j, 1);
      });
    },
    async createTodoOption() {
      this.error = [];
      await this.$store
        .dispatch('createTripTravelTodo', this.modalData)
        .then((res) => {
          const data = new Object();
          data.not_to_do = res.data.id;
          this.$store.dispatch('addToDoExp', data).then(() => {
            this.todoModal = false;
          });
          this.todoList.push(res.data);
        })
        .catch((e) => {
          this.error = e.response.data.error;
        });
    },
    async addTodoOption() {
      this.error = [];
      const data = new Object();
      data.not_to_do = this.idValue;
      await this.$store.dispatch('addToDoExp', data).then(() => {
        this.todoModal = false;
      });
    },
    async deleteTodo(i, j) {
      await this.$store.dispatch('deleteToDoExp', i.id).then(() => {
        this.data.not_to_do.splice(j, 1);
      });
    },
    async createFaqOption() {
      this.error = [];
      await this.$store
        .dispatch('createTripTravelFaq', this.modalData)
        .then((res) => {
          const data = new Object();
          data.faq = res.data.id;
          this.$store.dispatch('addFaqsExp', data).then(() => {
            this.faqModal = false;
          });
          this.faqList.push(res.data);
        })
        .catch((e) => {
          this.error = e.response.data.error;
        });
    },
    async addFaqOption() {
      this.error = [];
      const data = new Object();
      data.faq = this.idValue;
      await this.$store.dispatch('addFaqsExp', data).then(() => {
        this.faqModal = false;
      });
    },
    async deleteFaq(i, j) {
      await this.$store.dispatch('deleteFaqsExp', i.id).then(() => {
        this.data.faqs.splice(j, 1);
      });
    },
    clearModal() {
      this.idValue = '';
      this.modalData = {};
    },
  },
  watch: {
    whoModal() {
      this.clearModal();
    },
    knowModal() {
      this.clearModal();
    },
    todoModal() {
      this.clearModal();
    },
    faqModal() {
      this.clearModal();
    },
  },
  computed: {
    data() {
      return this.$store.getters.exp;
    },
    whoList() {
      return this.$store.getters.tripWho;
    },
    knowList() {
      return this.$store.getters.tripKnow;
    },
    todoList() {
      return this.$store.getters.tripTodo;
    },
    faqList() {
      return this.$store.getters.tripFaq;
    },
    textEnErrors() {
      const errors = [];
      this.error.find((item) => item == 'text_en__required') && errors.push('Please enter text');
      this.error.find((item) => item == 'text_en__invalid') && errors.push('Provided text is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    textArErrors() {
      const errors = [];
      this.error.find((item) => item == 'text_ar__required') && errors.push('Please enter text');
      this.error.find((item) => item == 'text_ar__invalid') && errors.push('Provided text is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    questionEnErrors() {
      const errors = [];
      this.error.find((item) => item == 'question_en__required') && errors.push('Please enter question');
      this.error.find((item) => item == 'question_en__invalid') && errors.push('Provided question is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    questionArErrors() {
      const errors = [];
      this.error.find((item) => item == 'question_ar__required') && errors.push('Please enter question');
      this.error.find((item) => item == 'question_ar__invalid') && errors.push('Provided question is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    answerEnErrors() {
      const errors = [];
      this.error.find((item) => item == 'answer_en__required') && errors.push('Please enter answer');
      this.error.find((item) => item == 'answer_en__invalid') && errors.push('Provided answer is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    answerArErrors() {
      const errors = [];
      this.error.find((item) => item == 'answer_ar__required') && errors.push('Please enter answer');
      this.error.find((item) => item == 'answer_ar__invalid') && errors.push('Provided answer is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
  },
};
</script>

<style lang="scss">
.experiences-add {
  .v-card {
    border-radius: 10px;
    border-color: var(--v-gray-lighten1);
  }
  .v-btn {
    border-radius: 4px;
  }
}
</style>
