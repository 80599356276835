<template>
  <div class="experiences-schedule" :key="key">
    <v-card tile flat min-height="52" class="d-flex flex-wrap align-center" :class="!data.length ? 'ps-3' : ''">
      <v-btn
        depressed
        :style="`box-shadow: ${day == i ? '4px -2px 4px rgba(0, 0, 0, 0.08)' : '1px -1px 4px rgba(0, 0, 0, 0.04)'}; z-index: ${
          data.length - i
        }`"
        :color="day == i ? 'white' : 'gray lighten-3 text--text'"
        class="rounded-b-0 rounded-tl-0 px-7 me-n2"
        :height="52"
        @click="day = i"
        v-for="(j, i) in data"
        :key="i"
      >
        Day {{ i + 1 }}
      </v-btn>
      <v-btn v-if="!data.find((i) => i.id == undefined)" @click="addDay" icon class="me-4 ms-6 my-4 add-btn link">
        <v-icon color="gray lighten-1">mdi-plus</v-icon>
      </v-btn>
    </v-card>
    <v-divider></v-divider>
    <v-card tile flat height="62vh" class="d-flex justify-center text-center align-center" v-if="!data.length">
      <div>
        <v-img contain height="150" src="@/assets/img/wait.svg"></v-img>
        <div class="text--text my-4">There is no scheduled day yet</div>
        <v-btn @click="addDay" depressed width="106" height="30" color="primary" class="text-body-2 rounded">Add day</v-btn>
      </div>
    </v-card>
    <div v-if="data.length">
      <div class="pl-lg-9 pa-sm-7 pa-3 item-content">
        <div class="text-h6 font-weight-bold">Description</div>
        <div class="text--text mb-3">Provide the information in English and Arabic</div>
        <div class="d-flex flex-md-row flex-column multi-direct">
          <v-text-field
            class="field46 width100 me-md-3"
            v-model="data[day].title_en"
            placeholder="Title"
            :error-messages="titleEnErrors"
            outlined
            dense
            required
            color="primary"
            height="46"
          ></v-text-field>
          <v-text-field
            class="field46 width100 mt-md-0 mt-n2 ms-md-3"
            v-model="data[day].title_ar"
            placeholder="عنوان"
            :error-messages="titleArErrors"
            outlined
            dense
            required
            color="primary"
            height="46"
          ></v-text-field>
        </div>
        <div class="d-flex flex-md-row flex-column mt-n2 multi-direct">
          <v-textarea
            class="width100 me-md-3"
            v-model="data[day].description_en"
            placeholder="Description"
            :error-messages="desEnErrors"
            auto-grow
            rows="4"
            row-height="30"
            outlined
            dense
            required
            color="primary"
          ></v-textarea>
          <v-textarea
            class="width100 mt-md-0 mt-n2 ms-md-3"
            v-model="data[day].description_ar"
            placeholder="وصف كامل"
            :error-messages="desArErrors"
            auto-grow
            rows="4"
            row-height="30"
            outlined
            dense
            required
            color="primary"
          ></v-textarea>
        </div>
        <div v-if="data[day].id">
          <div class="font-weight-bold mb-3">Events</div>
          <div class="d-flex align-center flex-md-row flex-column multi-direct" v-for="(j, i) in data[day].events" :key="i + 200">
            <!-- <v-menu :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :id="`time${i}`"
                  class="field46 me-md-6"
                  style="min-width: 95px"
                  :value="j.time"
                  v-bind="attrs"
                  v-on="on"
                  :error-messages="timeErrors"
                  placeholder="__:__"
                  append-icon="mdi-clock-time-three"
                  outlined
                  dense
                  color="primary"
                  height="46"
                  readonly
                ></v-text-field>
              </template>
              <v-time-picker v-model="j.time" format="24hr" @click:minute="t(`time${i}`)"></v-time-picker>
            </v-menu> -->
            <v-text-field
              class="field46 me-md-6"
              style="min-width: 95px"
              :value="j.time"
              @click="openClock(j.time, i)"
              @click:append="openClock(j.time, i)"
              @change="j.id ? saveEvent(j) : ''"
              :error-messages="timeErrors"
              placeholder="__:__"
              append-icon="mdi-clock-time-three"
              outlined
              dense
              color="primary"
              height="46"
              readonly
            ></v-text-field>
            <v-text-field
              class="field46 width100 mt-md-0 mt-n2 me-md-3"
              v-model="j.text_en"
              @change="saveEvent(j)"
              placeholder="Title"
              :error-messages="textEnErrors"
              outlined
              dense
              required
              :disabled="!j.time"
              color="primary"
              height="46"
            ></v-text-field>
            <v-text-field
              class="field46 width100 mt-md-0 mt-n2 ms-md-3"
              v-model="j.text_ar"
              @change="saveEvent(j)"
              placeholder="عنوان"
              :error-messages="textArErrors"
              outlined
              dense
              required
              :disabled="!j.text_en"
              color="primary"
              height="46"
            ></v-text-field>
            <v-btn v-if="j.id" @click="removeEvent(j, i)" icon class="ms-2 mb-6"><v-icon color="gray lighten-1">mdi-close</v-icon></v-btn>
            <v-btn v-else width="36" height="36" icon class="ms-2 mb-6" disabled></v-btn>
          </div>
        </div>
        <div v-if="data[day].id">
          <div class="font-weight-bold mb-3">Items to bring</div>
          <div class="d-flex align-center flex-md-row flex-column multi-direct" v-for="(j, i) in data[day].requirements" :key="i + 100">
            <v-text-field
              class="field46 width100 me-md-3"
              v-model="j.text_en"
              @change="saveReq(j)"
              placeholder="Title"
              :error-messages="textEnErrors"
              outlined
              dense
              required
              color="primary"
              height="46"
            ></v-text-field>
            <v-text-field
              class="field46 width100 mt-md-0 mt-n2 ms-md-3"
              v-model="j.text_ar"
              @change="saveReq(j)"
              placeholder="عنوان"
              :error-messages="textArErrors"
              outlined
              dense
              required
              color="primary"
              height="46"
            ></v-text-field>
            <v-btn v-if="j.id" @click="removeReq(j, i)" icon class="ms-2 mb-6"><v-icon color="gray lighten-1">mdi-close</v-icon></v-btn>
            <v-btn v-else width="36" height="36" icon class="ms-2 mb-6" disabled></v-btn>
          </div>
        </div>
        <div v-if="data[day].id">
          <div class="font-weight-bold mb-3">Photo</div>
          <v-card flat class="primary lighten-3 d-flex align-center justify-center" height="200" max-width="360">
            <div v-if="!data[day].media.length && !image">
              <v-img class="mb-3" contain height="48" src="@/assets/icon/img.svg"></v-img>
              <v-btn @click="addPhoto" depressed width="116" height="30" class="primary text-body-2 rounded">Choose photo</v-btn>
            </div>
            <v-img @click="addPhoto" class="rounded-lg" v-else contain :src="image || data[day].media[0].url" height="200"></v-img>
          </v-card>
        </div>
      </div>
      <v-divider></v-divider>
      <div class="pl-lg-9 px-sm-7 px-3 py-3 item-content d-flex flex-wrap">
        <v-btn @click="removeDay" width="126" height="30" outlined class="rounded" color="gray">
          <span class="error--text text-body-2">Remove day</span>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn @click="saveDay" depressed color="primary" class="text-body-2 rounded" width="106" height="30">Save</v-btn>
      </div>
    </div>
    <v-dialog v-model="timeModal" persistent width="290px">
      <v-time-picker v-if="timeModal" v-model="time" full-width format="24hr">
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="doneClock">OK</v-btn>
      </v-time-picker>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      timeModal: false,
      time: null,
      event: 0,
      day: 0,
      key: 1,
      image: '',
      error: [],
    };
  },
  mounted() {
    this.$store.dispatch('getExpSchedule');
  },
  methods: {
    openClock(t, i) {
      this.timeModal = true;
      this.time = t;
      this.event = i;
    },
    doneClock() {
      this.timeModal = false;
      this.data[this.day].events[this.event].time = this.time;
    },
    notifi(btn) {
      if (btn == 'saved') {
        this.$notify({
          title: 'Done!',
          message: 'Schedule day added successfully',
          type: 'success',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == 'update') {
        this.$notify({
          title: 'Done!',
          message: 'Schedule day updated successfully',
          type: 'success',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == 'remove') {
        this.$notify({
          title: 'Done!',
          message: 'Schedule day removed',
          type: 'success',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    addPhoto() {
      const input = document.createElement('input');
      const self = this;
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'image/*');
      input.addEventListener('change', function(e) {
        if (this.files && this.files[0]) {
          self.addMediaItem(this.files[0]);
          var reader = new FileReader();
          reader.onload = (e) => {
            self.image = e.target.result;
          };
          reader.readAsDataURL(this.files[0]);
        }
      });
      input.click();
    },
    async addMediaItem(f) {
      this.error = [];
      const formData = new FormData();
      formData.append('type', 'picture');
      formData.append('media', f);
      await this.$store.dispatch('updateExpScheduleItemMedia', { id: this.data[this.day].id, data: formData }).catch((e) => {
        this.error = e.response.data.error;
      });
    },
    addDay() {
      this.data.push({});
      this.day = this.data.length - 1;
    },
    saveDay() {
      this.error = [];
      const dataRequest = this.data[this.day];
      if (!dataRequest.id) {
        const formData = new FormData();
        dataRequest.title_en ? formData.append('title_en', dataRequest.title_en) : '';
        dataRequest.title_ar ? formData.append('title_ar', dataRequest.title_ar) : '';
        dataRequest.description_en ? formData.append('description_en', dataRequest.description_en) : '';
        dataRequest.description_ar ? formData.append('description_ar', dataRequest.description_ar) : '';
        this.$store
          .dispatch('createExpScheduleItem', formData)
          .then((res) => {
            this.notifi('saved');
            res.data.events.push({ time: null });
            res.data.requirements.push({});
            this.data[this.day] = res.data;
            ++this.key;
          })
          .catch((e) => {
            this.error = e.response.data.error;
          });
      } else {
        const data = new Object();
        data.title_en = dataRequest.title_en;
        data.title_ar = dataRequest.title_ar;
        data.description_en = dataRequest.description_en;
        data.description_ar = dataRequest.description_ar;
        this.$store
          .dispatch('updateExpScheduleItem', { id: dataRequest.id, data: data })
          .then(() => {
            this.notifi('update');
          })
          .catch((e) => {
            this.error = e.response.data.error;
          });
      }
    },
    async saveEvent(i) {
      this.error = [];
      const data = new Object();
      data.text_en = i.text_en;
      data.text_ar = i.text_ar;
      data.time = i.time;
      if (i.id) {
        await this.$store
          .dispatch('updateExpScheduleItemEvent', { id: this.data[this.day].id, data: data, event: i.id })
          .then(() => {
            this.notifi('update');
          })
          .catch((e) => {
            this.error = e.response.data.error;
          });
      } else if (i.time || i.text_en || i.text_ar) {
        await this.$store
          .dispatch('createExpScheduleItemEvent', { id: this.data[this.day].id, data: data })
          .then((res) => {
            res.data.events.push({ time: null });
            res.data.requirements.push({});
            this.data[this.day] = res.data;
            ++this.key;
            this.notifi('update');
          })
          .catch((e) => {
            this.error = e.response.data.error;
          });
      }
    },
    async saveReq(i) {
      this.error = [];
      const data = new Object();
      data.text_en = i.text_en;
      data.text_ar = i.text_ar;
      if (i.id) {
        await this.$store
          .dispatch('updateExpScheduleItemReq', { id: this.data[this.day].id, data: data, req: i.id })
          .then(() => {
            this.notifi('update');
          })
          .catch((e) => {
            this.error = e.response.data.error;
          });
      } else if (i.text_en || i.text_ar) {
        await this.$store
          .dispatch('createExpScheduleItemReq', { id: this.data[this.day].id, data: data })
          .then((res) => {
            res.data.events.push({ time: null });
            res.data.requirements.push({});
            this.data[this.day] = res.data;
            ++this.key;
            this.notifi('update');
          })
          .catch((e) => {
            this.error = e.response.data.error;
          });
      }
    },
    async removeDay() {
      const i = this.day;
      this.day == 0 ? (this.day = 0) : this.day--;
      if (this.data[i].id) {
        await this.$store.dispatch('removeExpScheduleItem', this.data[i].id).then(() => {
          this.data.splice(i, 1);
        });
      } else {
        this.data.splice(i, 1);
      }
      ++this.key;
      this.notifi('remove');
    },
    async removeEvent(i, idx) {
      await this.$store.dispatch('removeExpScheduleItemEvent', { id: this.data[this.day].id, event: i.id }).then(() => {
        this.data[this.day].events.splice(idx, 1);
        ++this.key;
        this.notifi('update');
      });
    },
    async removeReq(i, idx) {
      await this.$store.dispatch('removeExpScheduleItemReq', { id: this.data[this.day].id, req: i.id }).then(() => {
        this.data[this.day].requirements.splice(idx, 1);
        ++this.key;
        this.notifi('update');
      });
    },
  },
  watch: {
    day() {
      this.error = [];
      this.image = '';
    },
  },
  computed: {
    data() {
      return this.$store.getters.expScheduleList;
    },
    titleEnErrors() {
      const errors = [];
      this.error.find((item) => item == 'title_en__required') && errors.push('Please enter title');
      this.error.find((item) => item == 'title_en__invalid') && errors.push('Provided title is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    titleArErrors() {
      const errors = [];
      this.error.find((item) => item == 'title_ar__required') && errors.push('Please enter title');
      this.error.find((item) => item == 'title_ar__invalid') && errors.push('Provided title is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    desEnErrors() {
      const errors = [];
      this.error.find((item) => item == 'description_en__required') && errors.push('Please enter description');
      this.error.find((item) => item == 'description_en__invalid') && errors.push('Provided description is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    desArErrors() {
      const errors = [];
      this.error.find((item) => item == 'description_ar__required') && errors.push('Please enter description');
      this.error.find((item) => item == 'description_ar__invalid') && errors.push('Provided description is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    textEnErrors() {
      const errors = [];
      this.error.find((item) => item == 'text_en__required') && errors.push('Please enter text');
      this.error.find((item) => item == 'text_en__invalid') && errors.push('Provided text is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    textArErrors() {
      const errors = [];
      this.error.find((item) => item == 'text_ar__required') && errors.push('Please enter text');
      this.error.find((item) => item == 'text_ar__invalid') && errors.push('Provided text is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    timeErrors() {
      const errors = [];
      this.error.find((item) => item == 'time__required') && errors.push('Please enter time');
      this.error.find((item) => item == 'time__invalid') && errors.push('Provided time is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
  },
  destroyed() {
    this.$store.dispatch('setExpSchedule');
  },
};
</script>

<style lang="scss">
.experiences-schedule {
  .add-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 20px;
    border: 1.4px solid var(--v-gray-lighten1);
    border-radius: 50px !important;
    i {
      font-size: 14px !important;
    }
  }
}
</style>
